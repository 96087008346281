import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { columntaxFilingFilingIdPut, getColumnTaxSettings } from "@/api/column-tax";
import { cookieNames, HOUR_IN_MS } from "@/config/constants";
import { router } from "@/router";
import {
  type ColumntaxDisclosureParty,
  type ColumntaxTaxReturn,
  CommonProductTypes,
  CommonSubscriptionStatus
} from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useFormsStore } from "@/store/forms.store";
import type { UserData } from "@/store/types";
import { getAllCookieKeys, getCookie, removeCookie, setCookie } from "@/util/cookies";
import { getLogger } from "@/util/logger";
import { ROUTE_NAMES } from "@/util/routes/route-names";

export interface DiyStore {
  consentToDiscloseId?: string | null;
  consentToUseId: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  disclosureToCompanies: ColumntaxDisclosureParty[] | null;
  isConsentToUseRequired: boolean | null;
  isConsentToDisclosureRequired: boolean | null;
  isNewAvailable: boolean | null;
  dashboardModalsState: {
    upgradeToColumnTax: boolean;
    columnTaxAccess: boolean;
    secondaryPurchaseConsent: boolean;
    reactivateMembership: boolean;
    reactivateDiy: boolean;
  };
}

export enum DashboardModalsStates {
  UpgradeToColumnTax = "upgradeToColumnTax",
  ColumnTaxAccess = "columnTaxAccess",
  SecondaryPurchaseConsent = "secondaryPurchaseConsent",
  ReactivateMembership = "reactivateMembership",
  ReactivateDiy = "reactivateDiy"
}

type PersistedStoreKeys = "consentToDiscloseId" | "consentToUseId";

type RedirectMobileCondition =
  | "hasThisTaxYearColumntaxReturn"
  | "isNewAvailable"
  | "hasActiveFilesmart"
  | "hasExpiredFilesmart"
  | "defaultAction";

export const getDiyStoreDataCookieName = (): string => {
  const userData = getCookie<UserData>(cookieNames.userData);
  const userId = userData?.id ?? "guest";
  return `${cookieNames.diyStoreData}-${userId}`;
};

const logger = getLogger("Store: Diy");

export const removeOtherAccountsDiyStoreData = () => {
  const currentAccountCookieKey = getDiyStoreDataCookieName();
  const cookieKeys = getAllCookieKeys();

  cookieKeys.forEach((key) => {
    if (key.startsWith(cookieNames.diyStoreData) && key !== currentAccountCookieKey) {
      removeCookie(key);
    }
  });
};

const cleanSavedStoreData: Pick<DiyStore, PersistedStoreKeys> = {
  consentToDiscloseId: null,
  consentToUseId: null
};

const cleanDynamicStoreData: Omit<DiyStore, PersistedStoreKeys> = {
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  disclosureToCompanies: null,
  isConsentToUseRequired: null,
  isConsentToDisclosureRequired: null,
  isNewAvailable: null,
  dashboardModalsState: {
    upgradeToColumnTax: false,
    columnTaxAccess: false,
    secondaryPurchaseConsent: false,
    reactivateMembership: false,
    reactivateDiy: false
  }
};

const savedDiyStoreData =
  getCookie<Pick<DiyStore, PersistedStoreKeys>>(getDiyStoreDataCookieName()) ?? cleanSavedStoreData;
removeOtherAccountsDiyStoreData();
const diyStoreData: DiyStore = {
  ...savedDiyStoreData,
  ...cleanDynamicStoreData
};

export const useDiyStore = defineStore("diy", {
  state: (): DiyStore => diyStoreData,
  actions: {
    setState(hintData: Partial<DiyStore>) {
      Object.keys(hintData).forEach((key) => {
        this[key] = hintData[key];
      });

      setCookie(
        getDiyStoreDataCookieName(),
        {
          consentToDiscloseId: this.consentToDiscloseId,
          consentToUseId: this.consentToUseId
        },
        HOUR_IN_MS
      );
    },
    async getHints() {
      try {
        logger.info("Get hints action call...");
        const {
          client_hint,
          disclosure_to_companies: disclosureToCompanies,
          is_consent_to_use_required: isConsentToUseRequired,
          is_consent_to_disclosure_required: isConsentToDisclosureRequired = null,
          is_new_available: isNewAvailable = null
        } = await getColumnTaxSettings();
        const {
          email = null,
          phone = null,
          first_name: firstName = null,
          last_name: lastName = null
        } = client_hint ?? {};

        this.setState({
          email,
          phone,
          firstName,
          lastName,
          disclosureToCompanies,
          isConsentToUseRequired,
          isConsentToDisclosureRequired,
          isNewAvailable
        });
      } catch (e) {
        Sentry.captureMessage(`Error trying to get diy hints: ${JSON.stringify(e)}`);
      }
    },
    setDashboardModalState(modalName: DashboardModalsStates, to: boolean) {
      this.dashboardModalsState[modalName] = to;
    },
    closeAllDashboardModals() {
      Object.keys(this.dashboardModalsState).forEach((key) => (this.dashboardModalsState[key] = false));
    },
    async redirectToCreateColumnTax() {
      await this.getHints();

      if (this.isConsentToUseRequired) {
        router.push({ name: ROUTE_NAMES.consentToUse });
        return;
      }

      if (this.isConsentToDisclosureRequired) {
        router.push({ name: ROUTE_NAMES.consentToDisclosure });
        return;
      }

      router.push({ name: ROUTE_NAMES.diyVerification });
    },
    async showAppropriateModal(stepsToSkip: RedirectMobileCondition[] = []) {
      const formStore = useFormsStore();

      if (!stepsToSkip.includes("hasThisTaxYearColumntaxReturn") || !stepsToSkip.includes("isNewAvailable")) {
        await Promise.all([formStore.getDiyColumnTaxReturns(), this.getHints()]);
      }

      const actions: Record<RedirectMobileCondition, () => void> = {
        hasThisTaxYearColumntaxReturn: () => this.setDashboardModalState(DashboardModalsStates.ColumnTaxAccess, true),
        isNewAvailable: () => this.redirectToCreateColumnTax(),
        hasActiveFilesmart: () => this.setDashboardModalState(DashboardModalsStates.SecondaryPurchaseConsent, true),
        hasExpiredFilesmart: () => this.setDashboardModalState(DashboardModalsStates.ReactivateMembership, true),
        defaultAction: () => this.setDashboardModalState(DashboardModalsStates.ReactivateDiy, true)
      };

      const conditions: Record<RedirectMobileCondition, boolean> = {
        hasThisTaxYearColumntaxReturn: this.hasThisTaxYearColumntaxReturn,
        isNewAvailable: Boolean(this.isNewAvailable),
        hasActiveFilesmart: this.hasActiveFilesmart,
        hasExpiredFilesmart: this.hasExpiredFilesmart,
        defaultAction: true
      };

      const condition =
        (Object.keys(conditions) as RedirectMobileCondition[])
          .filter((key) => !stepsToSkip.includes(key))
          .find((key) => conditions[key]) ?? "defaultAction";

      actions[condition]();
    },
    async handleNewColumnTaxReturn() {
      await this.showAppropriateModal(["hasThisTaxYearColumntaxReturn", "isNewAvailable"]);
    },
    async editFiling(filingId: string): Promise<ColumntaxTaxReturn | null> {
      try {
        logger.info("Edit filing action call...");
        return await columntaxFilingFilingIdPut(filingId);
      } catch (e) {
        Sentry.captureMessage(`Error trying to edit filing: ${JSON.stringify(e)}`);
        return null;
      }
    },
    editLastFiling() {
      const lastFiling = this.filings.at(-1);
      if (!lastFiling) {
        return;
      }

      const authStore = useAuthStore();

      if (authStore.userData.second_factor_confirmed) {
        window.open(`/return/diy/column-tax/${lastFiling.id}`, "_blank");
        this.closeAllDashboardModals();
        return;
      }

      router.push(`/return/diy/new/verify-account?id=${lastFiling.id}`);
      this.closeAllDashboardModals();
    }
  },
  getters: {
    fullName(state): string | null {
      if (!state.firstName && !state.lastName) {
        return null;
      }

      if (!state.firstName) {
        return state.lastName;
      }

      if (!state.lastName) {
        return state.firstName;
      }

      return `${state.firstName} ${state.lastName}`;
    },
    hasData(state): boolean {
      return [state.firstName, state.lastName, state.lastName, state.email, state.phone].some(Boolean);
    },
    hasThisTaxYearColumntaxReturn(): boolean {
      const formState = useFormsStore();
      const taxYear = new Date().getFullYear() - 1;
      return formState.diyColumnTaxReturns.some((filing) => filing.tax_year === taxYear);
    },
    hasActiveFilesmart(): boolean {
      const formsStore = useFormsStore();

      return formsStore.subscriptionStatuses.some(
        (subscription) =>
          (subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusYes ||
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed) &&
          subscription.subscription_type === CommonProductTypes.ProductTypesBundle
      );
    },
    hasExpiredFilesmart(): boolean {
      const cartStore = useCartStore();
      const formsStore = useFormsStore();

      return (
        // searching for fact that user had filesmart that is expired and double check that no active subscriptions exist now
        cartStore.purchases.some(
          (purchase) =>
            purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusExpired &&
            purchase.product_type === CommonProductTypes.ProductTypesBundle
        ) &&
        !formsStore.subscriptionStatuses.some(
          (subscription) => subscription.subscription_type === CommonProductTypes.ProductTypesBundle
        )
      );
    },
    filings() {
      const formState = useFormsStore();
      return formState.diyColumnTaxReturns;
    }
  }
});
