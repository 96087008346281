import { BRAND } from "@/config/constants";
import { FILE_DIRECT_ROUTES } from "@/util/routes/filedirect";
import { FILE_SMART_ROUTES } from "@/util/routes/filesmart";
import { FILE_TAX_ROUTES } from "@/util/routes/filetax";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

const engine = import.meta.env.VITE_ENGINE;

const COMMON_ROUTES = [
  {
    path: "/login/:step?",
    name: ROUTE_NAMES.login,
    component: () => import("@/views/login.vue"),
    meta: {
      title: `${BRAND} - Login`
    }
  },
  {
    path: "/logout",
    name: ROUTE_NAMES.logout,
    component: () => import("@/views/logout.vue"),
    meta: {
      title: "File-Tax - Logout"
    }
  },
  {
    path: "/helpdesk",
    name: ROUTE_NAMES.helpdesk,
    component: () => import("@/views/helpdesk.vue"),
    meta: {
      title: `${BRAND} - Login`
    }
  },
  {
    path: "/extension/:form?/:step?/:funnel?",
    name: ROUTE_NAMES.extension,
    component: () => import("@/views/extension-form.vue"),
    meta: {
      title: `${BRAND} - Extension Form`,
      requiresAuth: true
    }
  },
  {
    path: "/:type/thank-you",
    name: ROUTE_NAMES.thankYou,
    component: () => import("@/views/thank-you/index.vue"),
    meta: {
      title: `${BRAND} - Thank you`,
      requiresAuth: true
    }
  },
  {
    path: "/registration/:step?",
    name: ROUTE_NAMES.registration,
    component: () => import("@/views/registration.vue"),
    meta: {
      title: `${BRAND} - Registration`
    }
  },
  {
    path: "/registration/dfy/:step?",
    name: ROUTE_NAMES.dfyRegistration,
    component: () => import("@/views/dfy/registration.vue"),
    meta: {
      title: `${BRAND} - Registration`
    }
  },
  {
    path: "/status",
    name: ROUTE_NAMES.status,
    redirect: "/dashboard",
    meta: {
      title: `${BRAND} - Status`,
      requiresAuth: true
    }
  },
  {
    path: "/dashboard",
    component: () => import("@/views/dashboard.vue"),
    name: ROUTE_NAMES.dashboardIndex,
    meta: {
      layout: "dashboard",
      title: `${BRAND} - Dashboard`,
      requiresAuth: true
    }
  },
  {
    path: "/dfy/success",
    name: ROUTE_NAMES.dfySuccess,
    component: () => import("@/views/dfy/success.vue"),
    meta: {
      title: `${BRAND} - Next steps`,
      requiresAuth: true
    }
  },
  {
    path: "/dfy/next-steps",
    name: ROUTE_NAMES.nextSteps,
    component: () => import("@/views/next-steps.vue"),
    meta: {
      title: `${BRAND} - Next steps`,
      requiresAuth: true
    }
  },
  {
    path: "/dfy/engagement",
    name: ROUTE_NAMES.engagement,
    component: () => import("@/views/engage-letter.vue"),
    meta: {
      title: `${BRAND} - Engage letter`
    }
  },
  {
    path: "/dashboard/:section/:step?",
    name: ROUTE_NAMES.dashboard,
    component: () => import("@/views/dashboard.vue"),
    meta: {
      layout: "dashboard",
      title: `${BRAND} - Dashboard`,
      requiresAuth: true
    }
  },
  {
    path: "/planner/print",
    component: () => import("@/pages/dashboard/tax-planner/print.vue"),
    name: ROUTE_NAMES.printChecklist,
    meta: {
      title: `Tax Planner`,
      requiresAuth: true
    }
  },
  {
    path: "/auto-extend-info",
    name: ROUTE_NAMES.autoExtendInfo,
    redirect: "/"
  },
  {
    path: "/auto-extend-cancel",
    name: ROUTE_NAMES.autoExtendCancel,
    redirect: "/"
  },
  {
    path: "/forgot-password",
    name: ROUTE_NAMES.forgotPassword,
    component: () => import("@/views/forgot-password.vue"),
    meta: {
      title: `${BRAND} - Recover Password`
    }
  },
  {
    path: "/forgot-username",
    name: ROUTE_NAMES.forgotUsername,
    component: () => import("@/views/forgot-username.vue"),
    meta: {
      title: `${BRAND} - Recover Username`
    }
  },
  {
    path: "/email/confirm",
    name: ROUTE_NAMES.emailConfirm,
    component: () => import("@/views/email-confirm.vue"),
    meta: {
      title: `${BRAND} - Email Change Confirmation`
    }
  },
  {
    path: "/password/reset",
    name: ROUTE_NAMES.passwordReset,
    component: () => import("@/views/password-reset.vue"),
    meta: {
      title: `${BRAND} - Password Reset`
    }
  },
  {
    path: "/tax-return/diy/authenticate",
    name: ROUTE_NAMES.diyAuthenticate,
    component: () => import("@/views/diy/authenticate.vue"),
    meta: {
      title: `${BRAND} - Choose registration`,
      flow: FLOW.diyReturn
    }
  },
  {
    path: "/tax-return/diy/select-plan",
    name: ROUTE_NAMES.diySelectPlan,
    component: () => import("@/views/diy/select-plan.vue"),
    meta: {
      title: `${BRAND} - Purchase`,
      flow: FLOW.diyReturn,
      requiresAuth: true
    }
  },
  {
    path: "/tax-return/diy/purchase",
    name: ROUTE_NAMES.diyPurchase,
    component: () => import("@/views/diy/purchase.vue"),
    meta: {
      title: `${BRAND} - Purchase`,
      flow: FLOW.diyReturn,
      requiresAuth: true
    }
  },
  {
    path: "/land/:source",
    name: ROUTE_NAMES.land,
    component: () => import("@/pages/land/index.vue"),
    meta: {
      title: `${BRAND} - Land`
    }
  },
  {
    path: "/c/:code",
    name: ROUTE_NAMES.shortUrl,
    component: () => import("@/pages/land/short.vue"),
    meta: {
      title: `${BRAND} - Land`
    }
  },
  {
    path: "/1click/login/:token",
    name: ROUTE_NAMES.clickLogin,
    component: () => import("@/pages/magic-login/index.vue"),
    meta: {
      title: `${BRAND} - 1-Click Login`
    }
  },
  {
    path: "/personal-extension",
    name: ROUTE_NAMES.personalExtension,
    component: () => import("@/views/personal.vue"),
    meta: {
      title: `${BRAND} - Personal Extension`,
      layout: "full"
    }
  },
  {
    path: "/personal-extension-membership",
    name: ROUTE_NAMES.personalExtensionMembership,
    component: () => import("@/views/personal-membership.vue"),
    meta: {
      title: `${BRAND} - Personal Extension Membership`,
      layout: "full"
    }
  },
  {
    path: "/member-benefits",
    name: ROUTE_NAMES.memberBenefits,
    component: () => import("@/views/member-benefits.vue"),
    meta: {
      title: `${BRAND} - Members Benefits Extension`,
      layout: "full"
    }
  },
  {
    path: "/business-extension",
    name: ROUTE_NAMES.businessExtension,
    component: () => import("@/views/business.vue"),
    meta: {
      title: `${BRAND} - Business Extension`,
      layout: "full"
    }
  },
  {
    path: "/session-expired",
    name: ROUTE_NAMES.sessionExpired,
    component: () => import("@/views/session-expired.vue"),
    meta: {
      title: `${BRAND} - Session Expired`
    }
  },
  {
    path: "/checkout/return",
    name: ROUTE_NAMES.checkout,
    component: () => import("@/views/checkout.vue"),
    meta: {
      title: `${BRAND} - Checkout`
    }
  },
  {
    path: "/tax-return/dfy/authenticate",
    name: ROUTE_NAMES.dfyAuthenticate,
    component: () => import("@/views/dfy/authenticate.vue"),
    meta: {
      title: `${BRAND} - Choose registration`,
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/login/dfy",
    name: ROUTE_NAMES.dfyLogin,
    component: () => import("@/views/dfy/login.vue"),
    meta: {
      title: `${BRAND} - Login`
    }
  },
  {
    path: "/login/dfy-v2",
    name: ROUTE_NAMES.dfyLoginV2,
    component: () => import("@/views/dfy/login-v2.vue"),
    meta: {
      title: `${BRAND} - Login`,
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/login/diy",
    name: ROUTE_NAMES.diyLogin,
    component: () => import("@/views/diy/login.vue"),
    meta: {
      title: `${BRAND} - Login`,
      flow: FLOW.diyReturn
    }
  },
  {
    path: "/registration/dfy-v2",
    name: ROUTE_NAMES.dfyRegistrationV2,
    component: () => import("@/views/dfy/registration-v2.vue"),
    meta: {
      title: `${BRAND} - Registration`,
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/registration/diy",
    name: ROUTE_NAMES.diyRegistration,
    component: () => import("@/views/diy/registration.vue"),
    meta: {
      title: `${BRAND} - Registration`,
      flow: FLOW.diyReturn
    }
  },
  {
    path: "/forgot-password/dfy-v2",
    name: ROUTE_NAMES.dfyForgotPasswordV2,
    component: () => import("@/views/dfy/forgot-password-v2.vue"),
    meta: {
      title: `${BRAND} - Forgot Password`,
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/forgot-password/dfy",
    name: ROUTE_NAMES.dfyForgotPassword,
    component: () => import("@/views/dfy/forgot-password.vue"),
    meta: {
      title: `${BRAND} - Forgot Password`
    }
  },
  {
    path: "/forgot-password/diy",
    name: ROUTE_NAMES.diyForgotPassword,
    component: () => import("@/views/diy/forgot-password.vue"),
    meta: {
      title: `${BRAND} - Forgot Password`,
      flow: FLOW.diyReturn
    }
  },
  {
    path: "/tax-return/dfy/select-plan",
    name: ROUTE_NAMES.dfySelectPlan,
    component: () => import("@/views/dfy/select-plan.vue"),
    meta: {
      title: `${BRAND} - Select Plan`,
      flow: FLOW.dfyReturn,
      requiresAuth: true
    }
  },
  {
    path: "/tax-return/dfy/purchase",
    name: ROUTE_NAMES.dfyPurchase,
    component: () => import("@/views/dfy/purchase.vue"),
    meta: {
      title: `${BRAND} - Purchase`,
      flow: FLOW.dfyReturn,
      requiresAuth: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/not-found.vue"),
    meta: {
      title: `${BRAND} - Page Not Found`
    }
  },
  {
    path: "/return/diy/new/consent-to-use",
    name: ROUTE_NAMES.consentToUse,
    component: () => import("@/views/diy/column/consent-to-use.vue"),
    meta: {
      title: `${BRAND} - Consent To Use`,
      requiresAuth: true,
      layout: "full"
    }
  },
  {
    path: "/return/diy/new/consent-to-disclosure",
    name: ROUTE_NAMES.consentToDisclosure,
    component: () => import("@/views/diy/column/consent-to-disclosure.vue"),
    meta: {
      title: `${BRAND} - Consent To Disclosure`,
      requiresAuth: true,
      layout: "full"
    }
  },
  {
    path: "/return/diy/new/verify-account",
    name: ROUTE_NAMES.diyVerification,
    component: () => import("@/views/diy/column/verify-account.vue"),
    meta: {
      title: `${BRAND} - Verify your account`,
      requiresAuth: true,
      layout: "full"
    }
  },
  {
    path: "/return/diy/column-tax/:filingId",
    name: ROUTE_NAMES.columnTax,
    component: () => import("@/views/diy/column/column-tax.vue"),
    meta: {
      title: `${BRAND} - Column tax account`,
      requiresAuth: true
    }
  },
  {
    path: "/tax-return/diy/registration",
    name: ROUTE_NAMES.dare2compareRegistration,
    component: () => import("@/views/dare2compare/registration.vue"),
    meta: {
      title: `${BRAND} - Dare2Compare Registration`
    }
  },
  {
    path: "/tax-return/diy/login",
    name: ROUTE_NAMES.dare2compareLogin,
    component: () => import("@/views/dare2compare/login.vue"),
    meta: {
      title: `${BRAND} - Dare2Compare Login`
    }
  },
  {
    path: "/return/diy/checkout",
    name: ROUTE_NAMES.dare2compareCheckout,
    component: () => import("@/views/dare2compare/checkout.vue"),
    meta: {
      title: `${BRAND} - Dare2Compare Checkout`,
      requiresAuth: true,
      layout: "full"
    }
  }
];

const BRAND_SPECIFIC_ROUTES = {
  filetax: FILE_TAX_ROUTES,
  filesmart: FILE_SMART_ROUTES,
  filedirect: FILE_DIRECT_ROUTES
};

const ENGINE_SPECIFIC_ROUTES = BRAND_SPECIFIC_ROUTES[engine] ?? [];

export const ROUTES = [...COMMON_ROUTES, ...ENGINE_SPECIFIC_ROUTES];
