export const ROUTE_NAMES = Object.freeze({
  faq: "faq",
  doneForYou: "done-for-you",
  contactUs: "contact-us",
  landing: "landing",
  login: "login",
  logout: "logout",
  helpdesk: "helpdesk",
  extension: "extension",
  thankYou: "thank-you",
  registration: "registration",
  status: "status",
  dashboardIndex: "dashboard-index",
  dfySuccess: "dfy-success",
  nextSteps: "next-steps",
  engagement: "engagement",
  dashboard: "dashboard",
  printChecklist: "print-checklist",
  autoExtendInfo: "auto-extend-info",
  autoExtendCancel: "auto-extend-cancel",
  forgotPassword: "forgot-password",
  forgotUsername: "forgot-username",
  emailConfirm: "email-confirm",
  passwordReset: "password-reset",
  doneDorYou: "done-for-you",
  doneForYouV2: "done-for-you-v2",
  land: "land",
  shortUrl: "short-url",
  clickLogin: "1click-login",
  personalExtension: "personal-extension",
  personalExtensionMembership: "personal-extension-membership",
  memberBenefits: "member-benefits",
  businessExtension: "business-extension",
  sessionExpired: "session-expired",
  checkout: "checkout",
  dfyAuthenticate: "dfy-authenticate",
  dfyLogin: "dfy-login",
  dfyLoginV2: "dfy-login-v2",
  dfyRegistrationV2: "dfy-registration-v2",
  dfyRegistration: "dfy-registration",
  dfyForgotPasswordV2: "dfy-forgot-passwordv2",
  dfyForgotPassword: "dfy-forgot-password",
  dfySelectPlan: "dfy-select-plan",
  dfyPurchase: "dfy-purchase",
  doItYourself: "do-it-yourself",
  commonMistakes: "common-mistakes",
  diyAuthenticate: "diy-authenticate",
  diySelectPlan: "diy-select-plan",
  diyLogin: "diy-login",
  diyForgotPassword: "diy-forgot-password",
  diyRegistration: "diy-registration",
  diyPurchase: "diy-purchase",
  consentToUse: "consent-to-use",
  consentToDisclosure: "consent-to-disclosure",
  diyVerification: "diy-verification",
  columnTax: "column-tax",
  taxExtensionPersonal: "tax-extension-personal",
  dare2compareRegistration: "dare2compare-registration",
  dare2compareLogin: "dare2compare-login",
  dare2compareCheckout: "dare2compare-checkout"
});

export const FLOW = Object.freeze({
  dfyReturn: "/tax-return-preparation/done-for-you-v2",
  diyReturn: "/tax-return-preparation/do-it-yourself"
});
